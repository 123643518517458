<template>
  <div class="testimonials">
    <div class="testimonials__header layout__row">
      <div class="heading heading--h5">
        {{ block.subtitle }}
      </div>
      <h2 class="testimonials__title heading heading--h2">
        {{ block.title }}
      </h2>
      <p class="testimonials__text paragraph">
        {{ block.text }}
      </p>
    </div>
    <div v-if="!isMobileDevice" class="testimonials__carousel">
      <Swiper v-bind="swiperParams" @swiper="onSwiper">
        <SwiperSlide v-for="(item, index) in block.testimonial" :key="index">
          <TestimonialCard :testimonial="item" />
        </SwiperSlide>
      </Swiper>
      <div class="testimonials__pagination" />
      <div v-if="block.testimonial.length > swiperParams.slidesPerView" class="testimonials__navigation">
        <NuxtIcon
          name="circle-prev"
          filled
          class="testimonials__button swiper-pagination-prev"
          :class="{ 'testimonials__button--disabled': swiper?.isBeginning }"
          @click="changeSlide(Direction.Previous)"
        />
        <NuxtIcon
          name="circle-next"
          filled
          class="testimonials__button swiper-pagination-next"
          :class="{ 'testimonials__button--disabled': swiper?.isEnd }"
          @click="changeSlide(Direction.Next)"
        />
      </div>
    </div>
    <div v-else class="testimonials__carousel">
      <TestimonialCard
        v-for="(item, index) in block.testimonial"
        :key="index"
        :testimonial="item"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type PropType, ref } from 'vue';
import { type Swiper as SwiperRef } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Grid, Navigation, Pagination } from 'swiper/modules';

import TestimonialCard from '@/components/TestimonialCard.vue';
import { type Testimonials } from '@/types/Sulu';
import Direction from '@/constants/Direction';
import useUIHelper from '@/composables/useUIHelper';

defineProps({
  block: {
    type: Object as PropType<Testimonials>,
    required: true,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const i18n = useI18n();

const swiper = ref<SwiperRef>(null);

const breakpoint = {
  slidesPerView: 2,
  slidesPerGroup: 1,
  spaceBetween: 24,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  grid: { rows: 1 },
};

const swiperParams: any = {
  modules: [Grid, Navigation, Pagination],
  a11y: true,
  slidesPerView: 1,
  spaceBetween: 24,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  pagination: { el: '.testimonials__pagination', clickable: true },
  breakpoints: {
    768: {
      ...breakpoint,
    },
    1300: {
      ...breakpoint,
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1400: {
      ...breakpoint,
      slidesPerView: 3,
      spaceBetween: 45,
    },
  },
};

const changeSlide = (direction: Direction): void => {
  if (direction === Direction.Next) {
    swiper.value?.slideNext();
  }
  if (direction === Direction.Previous) {
    swiper.value?.slidePrev();
  }
};

const onSwiper = (swiperInstance: SwiperRef): void => {
  swiper.value = swiperInstance;
};

const { isMobileDevice } = useUIHelper();
</script>
<style lang="scss" scoped>
.testimonials {
  margin-block-start: 40px;

  @include respond-min($tablet) {
    margin-block-start: 80px;
  }

  &__header {
    text-align: center;
    @include respond-min($tablet) {
      margin-block-end: 40px;
    }
  }

  &__title {
    margin-block: 16px;
  }

  &__text {
    margin-block-start: 16px;
  }

  &__carousel {
    margin-block-start: 8px;
    max-inline-size: $testimonial-carousel-width;
    margin-inline: auto;
    position: relative;
    padding-block-end: 40px; // 64px + 48px;

    @include respond-min($tablet) {
      margin-block-start: 73px;
      padding-inline: $testimonial-carousel-padding;
      padding-block-end: 0;
    }
  }

  &__pagination {
    position: absolute;
    text-align: start;
    block-size: 48px;
    display: flex;
    align-items: center;
    inset-inline-start: 32px !important; // Override swiper styling
    inset-block-end: 0 !important; // Override swiper styling

    @include respond-min($tablet) {
      display: none;
    }

    :deep(.swiper-pagination-bullet) {
      margin-inline: 8px !important;
    }
  }

  &__navigation {
    position: absolute;
    display: flex;
    inset-inline-end: 0;
    inset-block-end: 0;
    inline-size: 100%;
    block-size: 100%;
    padding-inline: 16px;
    justify-content: flex-end;
    align-items: flex-end;

    @include respond-min($tablet) {
      padding-inline: 47px;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__button {
    line-height: 1;
    margin-inline-end: 16px;

    :deep(svg) {
      margin-block-end: 0;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
